const state = () => ({
    isMenuOpen: false,
    authenticateBeforeCheckout: true,
    hideScrollBar: false,
    alerts: [],
    currency: 'R$',
    storeName: 'UNIFESP',
    darkMode: JSON.parse(localStorage.getItem('theme'))  || false,
    showModalSearch: false,
    itensNavbar:  [
        {
            name: 'Home',
            path: '/'
        },
        {
            name: 'Sobre',
            path: '/about'
        },
        {
            name: 'Módulos',
            path: '/subjects'
        },
        // {
        //     name: 'Avaliações',
        //     path: '/avaliacoes'
        // },
        // {
        //     name: 'Cronograma',
        //     path: 'https://server-eadmedesporte.unifesp.br/api/file?fileName=Cronograma_MBA.pdf',
        //     target: '_blank'
        // }
        {
            name: 'Programação',
            path: '/timeline'
        },
        {
            name: 'Inscrição',
            path: '/register'
        },
    ],
})
  
const mutations = {
    TOGGLE_MENU(state, payload) {
        state.hideScrollBar = payload
        state.isMenuOpen = payload
    },
    TOGGLE_DARK_MODE(state) {
        localStorage.setItem('theme', JSON.stringify(!state.darkMode));
        state.darkMode = !state.darkMode;
    },
    SET_ALERT(state, alert){
        if(alert && alert.message != "Sessão expirada") {
            state.alerts.unshift(alert);
            setTimeout(() => {
                state.alerts.pop();
            }, 5000);
        }
    },
    SET_SHOW_MODAL_SEARCH(state, boolean){
        state.showModalSearch= boolean
    }
}

const getters = {
    getIsMenuOpen: state => state.isMenuOpen,
    getAlerts: state => state.alerts,
    getDarkMode: state => state.darkMode,
    getShowModalSearch: state => state.showModalSearch,
    getItensNavbar: state => state.itensNavbar
}

export default {
    state,
    getters,
    mutations
};