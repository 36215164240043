import AuthService from '../../services/AuthService'
import router from '../../router'

const state = () => ({
    isAuthenticated: false,
    loadingUser: false,
    redirect: '/',
    token: localStorage.getItem('token') || null,
    user: JSON.parse(localStorage.getItem('user')) || null,
    register:  JSON.parse(localStorage.getItem('register')) || {
        pessoais: null,
        especificos: null,
        enderecos: null
    },
    registerSteps: localStorage.getItem('registerSteps') || 1
})
  
const mutations = {
    REDIRECT: (state, payload) => {
        state.redirect = payload;
    },
    SET_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload));

        const backPage = state.redirect
        state.redirect = '/'
        state.isAuthenticated = true;
        state.user = payload
        state.token = payload.token
        if(router.currentRoute.path === '/auth' && !backPage){
            router.push({path: `/`});
        }else{
            router.push({path: `${backPage}`});
        }
        state.redirect = '/';
    },
    AUTH_USER: (state, payload) => {
        localStorage.setItem('token', payload.token)
        localStorage.setItem('user', JSON.stringify(payload));
       
        state.isAuthenticated = true;
        state.user = payload;
    },
    SET_LOADING_USER: (state, payload) => {
        state.loadingUser = payload
    },
    LOG_USER_OUT(state){
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('notifications');
        localStorage.removeItem('subjects');
        localStorage.removeItem('aulasAssistidas');
        localStorage.removeItem('pages');
        localStorage.removeItem('page');
        state.token = null;
        state.isAuthenticated = false;
        state.user = null;
        state.notifications = [];
        state.redirect = '/';
        if(router.currentRoute.value.path !== '/auth'){
            router.push({path: `/auth`});
        }
    },
    SET_USER_REGISTER(state, data) {
        state.userRegister = data;
    },
    LIMPAR_REGISTER(state) {
        localStorage.removeItem('register');
        localStorage.removeItem('registerSteps');

        state.userRegister = null;
        state.registerSteps = 1;
        state.register = {
            pessoais: null,
            especificos: null,
            enderecos: null
        };
    },
    SET_DADOS_PESSOAIS(state, data) {
        state.register.pessoais = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_ESPECIFICOS(state, data) {
        state.register.especificos = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_DADOS_ENDERECO(state, data) {
        state.register.enderecos = data;
        localStorage.setItem('register', JSON.stringify(state.register));
    },
    SET_STAGE_REGISTER(state, etapa) {
        state.registerSteps= etapa;
        localStorage.setItem('registerSteps', JSON.stringify(etapa));
    },
    SET_BACK_STAGE_REGISTER(state) {
        state.registerSteps = --state.registerSteps;
    }
}

const actions = {
    async loginUser({commit, dispatch}, credentials ){
        try {
            commit('SET_LOADING_USER', true);
            const response = await AuthService.login(credentials);
            await commit('SET_USER', response.data);
            dispatch('fetchSubjects', { keyword: ''})
            dispatch('notificationsUser')
            commit('SET_LOADING_USER', false);
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerUser({commit, state}){
        try{
            commit('SET_LOADING_USER', true);
            const response = await AuthService.create(state.register);
            commit('SET_USER_REGISTER', response.data.user);
            commit('SET_ALERT', {
                heading: 'success',
                message: response.data.message
            });
            commit('SET_STAGE_REGISTER', 4);
            commit('SET_LOADING_USER', false);
            return true;
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
            return false;
        }
    },
    async fetchUser({commit}){
        try{
            commit('SET_LOADING_USER', true)
            const response = await AuthService.getUser();
            commit('AUTH_USER', response.data)
            commit('SET_LOADING_USER', false)
        } catch (error) {
            commit('SET_LOADING_USER', false);
            commit('LOG_USER_OUT');
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async updateUser({commit}, payload){
        try {
            const response = await AuthService.update(payload);
            commit('SET_USER', response.data);
        } catch (error) {
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosPessoais({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_PESSOAIS', data);
            commit('SET_STAGE_REGISTER', 2);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosEspecificos({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_ESPECIFICOS', data);
            commit('SET_STAGE_REGISTER', 3);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
    async registerDadosEndereco({commit}, { data }){
        try{
            commit('SET_LOADING_USER', true);
            commit('SET_DADOS_ENDERECO', data);
            // commit('SET_STAGE_REGISTER', 4);
            commit('SET_LOADING_USER', false);
        }catch(error){
            commit('SET_LOADING_USER', false);
            commit('SET_ALERT', {
                heading: 'error',
                message: error.response.data.message
            });
        }
    },
}

const getters = {
    getUser: state => state.user,
    getLoadingUser: state => state.loadingUser,
    getRegisterSteps: state => state.registerSteps,
    getDataRegisters: state => state.register

}

export default {
    state,
    getters,
    mutations,
    actions
};